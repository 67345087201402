import { graphql } from "gatsby"
import * as React from "react"
import { Layout } from "../components/Layout"
import { BlockContent } from "../components/BlockContent"
import { createLocaleTextGetter } from "../../localize"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { LocaleContext } from "../components/LocaleContext"

export const query = graphql`
  fragment genericPageBlocksFragment on SanityGenericPage {
    blocks {
      __typename
      ...videoFragment
      ...carouselFragment
      ...promoFragment
      ...verticalGroupFragment
      ...horizontalGroupFragment
      ...textBlockFragment
      ...richTextBlockFragment
      ...imageBlockFragment
      ...overlineFragment
      ...formFragment
      ...navigationFragment
      ...linkFragment
    }
  }

  query ($regex: String!) {
    sanityGenericPage(_id: { regex: $regex }) {
      _id
      background {
        color {
          hex
        }
        variant
        image {
          asset {
            gatsbyImageData
          }
        }
      }
      ...genericPageBlocksFragment
    }
    allStock {
      nodes {
        product
        venues
      }
    }
  }
`

const GenericPageWrapper = styled.div`
  ${tw`w-full flex items-center justify-center`}
`

const Container = styled.div`
  width: 100%;
  max-width: 840px;
`

const GenericPage = ({ pageContext, data, location }) => {
  const getLocalizedContent = createLocaleTextGetter(pageContext.locale)
  const { sanityGenericPage } = getLocalizedContent(data)
  console.log("sanityGenericPage", sanityGenericPage)

  return (
    <LocaleContext.Provider value={pageContext.locale}>
      <Layout location={location}>
        <GenericPageWrapper
          style={{
            backgroundColor: sanityGenericPage.background?.color?.hex,
          }}
        >
          <Container>
            <BlockContent
              blocks={sanityGenericPage.blocks}
              blockWrapper={({ children }) => children}
            />
          </Container>
        </GenericPageWrapper>
      </Layout>
    </LocaleContext.Provider>
  )
}

export default GenericPage
